<template>
  <div id="page_container_none" class="fullscreen bg-white q-pa-md flex canna_page404" style="flex-direction: column; flex-wrap: nowrap !important;">
    <div class="noselect">
      <div class="header_title">Canna</div>
    </div>
    <div class="flex text-center noselect" style="flex: 1;flex-direction: column;"
      :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:54px': 'margin-top:84px'"
    >
      <div>
        <div class="main_title">
          Verificando correo electrónico
        </div>
        <div class="main_subtitle" :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:8px': 'margin-top:32px'">
          Solo un momento más, estamos terminando <template v-if="(pageSize === 'xs') ? false :true"><br></template>
          de verificar tu dirección de correo electrónico
        </div>
      </div>
      <div class="" :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:40px': 'margin-top:64px'">
        <div>
          <img src="images/canna_verificaemail.svg" :style="(pageSize === 'xs' || pageSize === 'sm')  ? 'width: 327px;' : ''">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myplatform_Mixin from '../mixins/platformMix.js'
export default {
  name: 'VerificaMail',
  mixins: [
    myplatform_Mixin
  ],
  components:{
    //cxBackground: require('components/appBackgrounds').default
  },
  data () {
    return {
      currentSizeNodata : '327px',
    }
  },
  methods:{
    go_HomePage () {
      console.log("go home")
      window.location = '/'
    }
  }
}
</script>
